import React from 'react';
import Button from '../button/button';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import useQuery from '../../utilities/use-query';
import {useLazyLoadQuery} from 'react-relay/hooks';
import moment from 'moment';
import MinusSvg from '../../assets/icons0/minus.svg';
import PlusSvg from '../../assets/icons0/plus.svg';
import Lock from '../../assets/icons0/lock-unlocked-01.svg';
import ArrowLeft from '../../assets/icons0/arrow-left.svg';
import StepsBar from './steps-bar';
import {useForm} from 'react-hook-form';
import MessageTextSquare01Icon from '../../assets/icons0/message-text-square-01.svg';
import {graphqlOperatorInterfaceServiceByIdQuery} from './graphql';
import classNames from 'classnames';
import {useOperatorDispatch, useOperatorState} from './operator-interface';
import getImageUrl from './get-image-url';
import searchFilter from '../../utilities/search-filter';
import getTagValue from '../../utilities/get-tag-value';
import i18n from '../../utilities/i18n';
import PopUp from '../pop-up/pop-up';
import MinusCircleSvg from '../../assets/icons0/minus-circle.svg';
import PlusCircleSvg from '../../assets/icons0/plus-circle.svg';

const serviceIdEx = process.env.RAZZLE_APP_SERVICE_ID;

const EndpointPopUp = ({endpoint, resetEndpoint}) => {
  const {serviceId = serviceIdEx, locale = 'uk'} = useQuery(); 
  const state = useOperatorState()[serviceId];
  const dispatch = useOperatorDispatch();
  const endpointState = state.endpoints[endpoint.id] || {
    id: endpoint.id
  };
  const expired = isNaN(endpointState.expired) ? 0 : endpointState.expired;
  const inventory = isNaN(endpointState.inventory) ? 0 : endpointState.inventory;
  const outcome = isNaN(endpointState.outcome) ? 0 : endpointState.outcome;
  const [isOutcome, setIsOutcome] = React.useState();
  const {register, setValue, handleSubmit, watch} = useForm({
    defaultValues: {
      actualQuantity: endpoint.quantity + inventory - expired - outcome,
      outcome: outcome
    }
  });
  const actualQuantity = watch('actualQuantity');
  const outcomeField = watch('outcome');
  const setInputValue = (increase) => {
    const fieldName = isOutcome ? 'outcome' : 'actualQuantity';
    const currentValue = isOutcome ? outcomeField : actualQuantity;
    if (increase) {
      setValue(fieldName, currentValue + 1);
    } else {
      setValue(fieldName, currentValue - 1);
    }
  }
  const saveChanges = handleSubmit((data) => {
    const endpointData = {...endpointState};
    if (isOutcome) {
      endpointData.outcome = data.outcome;
    } else {
      endpointData.inventory = data.actualQuantity - (endpoint.quantity - expired - outcome);
      endpointData.checked = true;
    }
    dispatch({type: 'update-endpoint', payload: {serviceId, endpoint: endpointData}});
    resetEndpoint();
  });
  return (
    <div className='mm-popup position-fixed top-0 left-0 right-0 bottom-0'>
      <div className='wrapper position-absolute left-1rem right-1rem background-color-white margin-0'>
        <h3 className='text-align-center padding-bottom-1rem text-lg medium color-gray-900'>{i18n(endpoint, 'name', locale)}</h3>
        <label htmlFor='isOutcome' className='display-flex cursor-pointer justify-content-center padding-bottom-1dot5rem'>
          <input id='isOutcome' type='checkbox' className='display-none' onChange={() => {setIsOutcome(!isOutcome)}}/>
          <div className='padding-right-0dot75rem text-md'>
              <p className={classNames('medium', {'color-gray-700': !isOutcome, 'color-gray-400': isOutcome})}>
                Фактично
              </p>
          </div>
          <div className={classNames('toggle', {'toggle--active': isOutcome})}></div>
          <div className='padding-left-0dot75rem text-md'>
              <p className={classNames('medium', {'color-gray-700': isOutcome, 'color-gray-400': !isOutcome})}>
                Повернення
              </p>
          </div>
        </label>
        <div className='mm-popup-quantity-selector'>
          <Button
            {...{ 
              size: 'xl',
              clickHandler: (e) => {
                setInputValue(false);
              },
              isLoading: false,
              fluid: 'always',
              disabled: (!isOutcome && actualQuantity === 0) || (isOutcome && outcomeField === 0)
            }}
          >
            <MinusSvg className='minus-icon'/> 
          </Button>
          {!isOutcome &&
            <input
              className='mm-popup-input color-gray-700 background-color-gray-50'
              type='text'
              {...register('actualQuantity')}
              readOnly
            />
          }
          {isOutcome &&
            <input
              className='mm-popup-input color-gray-700 background-color-gray-50'
              type='text'
              {...register('outcome')}
              readOnly
            />
          }
          <Button
            {...{ 
              size: 'xl',
              clickHandler: (e) => {
                setInputValue(true);
              },
              isLoading: false,
              fluid: 'always',
              disabled: isOutcome && outcomeField === actualQuantity
            }}
          >
            <PlusSvg className='minus-icon'/> 
          </Button>
        </div>
        <div className='margin-bottom-0dot75rem display-block'>
          <Button
            {...{
              color: 'blue-gradient',
              size: 'xl',
              clickHandler: saveChanges,
              isLoading: false,
              fluid: 'always',
              disabled: false
            }}
          >
            Зберегти
          </Button>
        </div>
        <Button
          {...{
            size: 'xl',
            clickHandler: resetEndpoint,
            isLoading: false,
            fluid: 'always',
            disabled: false
          }}
        >
          <span className='color-gray-500'>Скасувати</span>
        </Button>
      </div>
    </div>
  )
};

const EndpointRow = React.memo(({item, setEndpoint}) => {
  const img = getImageUrl(item.details);
  const {serviceId = serviceIdEx, locale = 'uk'} = useQuery(); 
  const state = useOperatorState()[serviceId];
  const endpointState = state.endpoints[item.id] || {};
  const expired = isNaN(endpointState.expired) ? 0 : endpointState.expired;
  const outcome = isNaN(endpointState.outcome) ? 0 : endpointState.outcome;
  const inventory = isNaN(endpointState.inventory) ? 0 : endpointState.inventory;
  return (
    <div
      className='table-row cursor-pointer'
      onClick={() => setEndpoint(item)}
    >
      <div className='cover width-2dot5rem'>
        <img className='width-2dot5rem height-2dot5rem border-radius-0dot5rem' src={img}/>
      </div>
      <div className='margin-left-0dot75rem flex-grow-1'>
        <div className='display-flex align-items-center'>
          <div className='text-sm medium color-gray-900'>
            {i18n(item, 'name', locale)}
          </div>
          {!!endpointState.comment && <MessageTextSquare01Icon className='width-1rem height-1rem margin-left-0dot5rem color-gray-400'/>}
        </div>
        <div className={classNames('text-sm color-gray-500 regular', {})}>
          {item.end && moment(item.end).format('DD.MM.YYYY')}
        </div>
      </div>
      <div className='quantity-column'>
        {(!!expired || !!inventory || !!outcome) &&
          <div className='text-sm color-gray-500 regular' >
            ({`${item.quantity}${(expired || outcome) ? ', -' + (expired + outcome) : ''}`})&nbsp;
          </div>
        }
        <div className={classNames('text-sm', {'color-gray-900 medium': endpointState.checked, 'color-gray-500 regular': !endpointState.checked})} >
          {item.quantity + inventory - expired - outcome}
        </div>
      </div>
    </div>
  )
});

const ExpiredEndpointRow = ({endpoint, index, register, watch, setValue}) => {
  const {locale = 'uk'} = useQuery();
  const expired = watch(endpoint.id);
  return (
    <div className={classNames('padding-bottom-1rem display-flex', {'padding-top-1rem': index === 0})}>
      <div className='flex-1 overflow-hidden'>
        <p className='text-sm medium color-gray-900 white-space-nowrap'>{i18n(endpoint, 'name', locale)}</p>
        <p className='text-sm color-error-500'>{moment(endpoint.end).format('DD.MM.YYYY')}</p>
      </div>
      <div className='display-flex align-self-flex-start'>
          <Button
            {...{ 
              size: 'xl',
              color: 'primary',
              plain: true,
              clickHandler: (e) => {
                setValue(endpoint.id, expired - 1);
              },
              disabled: expired === 0
            }}
          >
            <MinusCircleSvg className='display-block width-1dot25rem height-1dot25rem'/> 
          </Button>
          <input
            className='color-gray-700 width-2rem margin-left-0dot5rem margin-right-0dot5rem text-align-center'
            type='text'
            {...register(endpoint.id)}
            readOnly
          />
          <Button
            {...{ 
              size: 'xl',
              color: 'primary',
              plain: true,
              clickHandler: (e) => {
                setValue(endpoint.id, expired + 1);
              },
              disabled: expired === endpoint.quantity
            }}
          >
            <PlusCircleSvg className='display-block width-1dot25rem height-1dot25rem'/> 
          </Button>
      </div>
    </div>
  )
};

const Footer = React.memo(({endpointsQuantity}) => {
  const {serviceId = serviceIdEx} = useQuery(); 
  const state = useOperatorState()[serviceId];
  const dispatch = useOperatorDispatch();
  const cancelCheck = () => {
    dispatch({type: 'start', payload: {serviceId}});
  }
  const checkedEndpointsQuantity = Object.values(state.endpoints).filter(e => e.checked).length;
  return (
    <div className='tfoot'>
      <div className='thead-left text-sm color-gray-500'>
        Перевірено {checkedEndpointsQuantity} з {endpointsQuantity}
      </div>
      {!!checkedEndpointsQuantity ? 
        <div
          onClick={cancelCheck}
          className='thead-right text-sm color-error-600 medium'
        >
          Відмінити списання
        </div>
      :
        <div
          className='thead-right text-sm color-error-300 medium'
        >
          Відмінити списання
        </div>
      }
    </div>
  )
});

const ExpiredEndpoints = React.memo(({endpoints}) => {
  const [hasExpired, setHasExpired] = React.useState();
  const {serviceId = serviceIdEx, locale = 'uk'} = useQuery();
  const dispatch = useOperatorDispatch();
  const state = useOperatorState()[serviceId];
  const endpointsExpired = React.useMemo(
    () => endpoints
          .filter(e => e.quantity > 0 && e.end && moment().diff(moment(e.end), 'days') >= 0)
          .sort((a, b) => getTagValue(a.tags, 'rank', ':', Infinity) - getTagValue(b.tags, 'rank', ':', Infinity)), 
  [endpoints]);
  const defaultValues = {};
  endpointsExpired.forEach(endpoint => {
    defaultValues[endpoint.id] = isNaN(state.endpoints[endpoint.id]?.expired) ? endpoint.quantity : state.endpoints[endpoint.id].expired
  });
  React.useEffect(() => {
    setHasExpired(!!endpointsExpired.length);
  }, []);
  const {register, handleSubmit, watch, setValue} = useForm({
    defaultValues
  });
  const saveChanges = handleSubmit(data => {
    endpointsExpired.forEach(endpoint => {
      dispatch({type: 'update-endpoint', payload: {serviceId, endpoint: {expired: data[endpoint.id], id: endpoint.id}}});
    });
    setHasExpired(false);
  });
  return (
    <PopUp {...{isVisible: hasExpired}}>
      <div className='padding-top-1dot25rem padding-right-1rem padding-left-1rem padding-bottom-1rem'>
        <p className='color-gray-900 text-lg medium padding-bottom-1dot5rem text-align-center'>Списання прострочення</p>
        <div className='padding-top-0dot75rem padding-bottom-0dot75rem text-sm medium color-gray-500'>Назва товару</div>
        <div className='border-top-1px-solid border-bottom-1px-solid border-color-gray-200'>
          {endpointsExpired.map((endpoint, index) =>
            <ExpiredEndpointRow key={endpoint.id} {...{endpoint, index, register, watch, setValue}}/>
          )}
        </div>
        <div className='padding-top-1dot5rem'>
          <Button {...{size: 'xl', color: 'blue-gradient', clickHandler: saveChanges, fluid: 'always'}}>
            Списати
          </Button>
        </div>
        <div className='padding-top-0dot75rem'>
          <Button {...{size: 'xl', clickHandler: () => setHasExpired(false), fluid: 'always'}}>
            <span className='color-gray-500'>Скасувати</span>
          </Button>
        </div>
      </div>
    </PopUp>
  )
});

const Step3 = () => {
  const [endpoint, setEndpoint] = React.useState();
  const {serviceId = serviceIdEx} = useQuery();
  const {serviceById} = useLazyLoadQuery(graphqlOperatorInterfaceServiceByIdQuery, {id: serviceId});
  const endpoints = React.useMemo(
    () => serviceById.endpoints
          .filter(e => getTagValue(e.tags, 'mmDisplay', ':', false) === 'true')
          .sort((a, b) => getTagValue(a.tags, 'rank', ':', Infinity) - getTagValue(b.tags, 'rank', ':', Infinity)), 
  [serviceById]);
  const location = useLocation();
  const resetEndpoint = React.useCallback(() => {
    setEndpoint(null);
  }, [setEndpoint]);
  const navigate = useNavigate();
  const operatorDispatch = useOperatorDispatch();
  const inventoryFinished = () => {
    const stockListIds = endpoints.map(e => e.id);
    operatorDispatch({type: 'set-stock-list', payload: {serviceId, stockListIds}});
    navigate(`../step-4${searchFilter(location.search)}`);
  };
  return (
    <>
      <div className='mm-header background-color-gray-50'>
        <div className='lock-button'>
          <Lock className='display-block color-gray-700 height-1dot25rem'/>  
        </div>
        <StepsBar/>
        <Link to={`../step-2${searchFilter(location.search)}`}>
          <ArrowLeft className='display-block color-gray-500 height-1dot5rem'/>  
        </Link>
      </div>
      <div className='content'>
        <div className='header-padding text-2xl medium color-gray-900 background-color-gray-50'>Перевірка залишків</div>
        <div className='thead background-color-gray-50'>
          <div className='thead-left text-xs color-gray-500'>
            Назва товару
          </div>
          <div className='quantity-column thead-right text-xs color-gray-500'>
            Кількість
          </div>
        </div>
        {endpoint && <EndpointPopUp {...{endpoint, resetEndpoint}}/>}
        {endpoints.map((item) =>
          <EndpointRow key={item.id} {...{item, setEndpoint}}/>
        )}
        <Footer {...{endpointsQuantity: endpoints.length}}/>
      </div>
      <div className='button-block-padding'>
          <Button
            {...{
              color: 'blue-gradient',
              size: 'xl',
              clickHandler: inventoryFinished,
              isLoading: false,
              fluid: 'always',
              disabled: false
            }}
          >
            Продовжити
          </Button>
      </div>
      <ExpiredEndpoints {...{endpoints}}/>
    </>
  );
}

export default React.memo(Step3);