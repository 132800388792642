import React, {useRef, useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import classNames from 'classnames';
import Search from '../../assets/icons0/search-md.svg';
import Xcircle from '../../assets/icons0/x-circle.svg';
import PlusCircle from '../../assets/icons/plus-circle.svg';
import useQuery from '../../utilities/use-query';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {graphqlOperatorInterfaceServiceByIdQuery} from './graphql';
import searchFilter from '../../utilities/search-filter';
import getImageUrl from './get-image-url';
import getTagValue from '../../utilities/get-tag-value';
import i18n from '../../utilities/i18n';

const serviceIdEx = process.env.RAZZLE_APP_SERVICE_ID;

const EndpointRow = React.memo(({item}) => {
  const navigate = useNavigate();
  const img = getImageUrl(item.details);
  const location = useLocation();
  const {locale = 'uk'} = useQuery();
  return (
    <div
      className='search-results-item display-flex justify-content-space-between border-bottom align-items-center'
      onClick={() => {
        navigate(`../step-5-2${searchFilter(location.search, {endpointId: item.id})}`);
      }}
    >
      <div className='cover width-2dot5rem'>
        <img className='width-2dot5rem height-2dot5rem border-radius-0dot5rem' src={img}/>
      </div>
      <div className='margin-left-0dot625rem flex-grow-1'>
        <div className='text-sm medium color-gray-900'>
          {i18n(item, 'name', locale)}
        </div>
      </div>
      <div className='display-flex align-items-center padding-0dot625rem'>
        <PlusCircle className='plus-circle-icon'/>
      </div>
    </div>
  )
});

const Step51 = () => {
  const {serviceId = serviceIdEx, locale = 'uk'} = useQuery();
  const {serviceById} = useLazyLoadQuery(graphqlOperatorInterfaceServiceByIdQuery, {id: serviceId});
  const endpoints = React.useMemo(
    () => serviceById.endpoints.slice().sort((a, b) => getTagValue(a.tags, 'rank', ':', Infinity) - getTagValue(b.tags, 'rank', ':', Infinity)), 
  [serviceById]);
  const [filter, setFilter] = React.useState('');
  const [searchFocused, setSearchFocused] = React.useState(false);
  const [searchTextAdded, setSearchTextAdded] = React.useState(false);
  const inputRef = useRef(null);
  const location = useLocation();
  useEffect(() => {
    inputRef.current.focus();
    window.scrollTo(0, 0);
  }, []);
  return (<>
    <div className='mm-header background-color-gray-50 mm-header--search'>
      <div className='display-flex position-relative width-100percent align-items-center'>
        <div className={classNames('search-input-with-scan-button', {
          'active': searchFocused,
          'search-text-added': searchTextAdded,
        })}>
          <Search className='search-icon'/>
          <input id='filter'
            name='filter'
            type='text'
            value={filter}
            onChange={event => {
              setFilter(event.target.value);
              setSearchTextAdded(inputRef.current.value.length > 0);
            }}
            className='form-row__input'
            placeholder='Пошук товарів'
            ref={inputRef}
            onBlur={() => setSearchFocused(false)}
            onFocus={() => setSearchFocused(true)}
            autoComplete='off'
          />
          {searchTextAdded &&
            <Xcircle className='x-circle-icon' onClick={() => {
              setFilter('');
              setSearchTextAdded(false);
            }}/>
          }
        </div>
        <div>
          <Link to={`../step-5${searchFilter(location.search)}`} className='text-sm margin-left-1dot125rem color-primary-600'>
            Скасувати
          </Link>
        </div>
      </div>  
    </div>
    <div className='fulfill-title text-lg medium border-bottom color-gray-900'>
      Результати пошуку
    </div>
    <div className='filtered-list'>
      {endpoints.filter((item) => i18n(item, 'name', locale).toLowerCase().includes(filter.toLowerCase())).map(item =>
        <EndpointRow key={item.id} {...{item}}/>
      )}
    </div>
  </>);
};

export default React.memo(Step51);