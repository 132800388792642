import React from 'react';
import './confirm.scss';
import {ErrorBoundary} from 'react-error-boundary';
import {Helmet} from 'react-helmet-async';
import {useLocation, useNavigate} from 'react-router-dom';
import FallbackComponent from './fallback-component';
import Skeleton from './skeleton/skeleton';
import searchFilter from '../utilities/search-filter';
import MasterCardSvg from '../assets/master-card.svg';
import VisaSvg from '../assets/visa.svg';
import ApplePaySvg from '../assets/apple-pay.svg';
import GooglePaySvg from '../assets/google-pay.svg';
import LockUnlocked03Svg from '../assets/icons0/lock-unlocked-03.svg';
import Top from './top';
import Browser from './browser';
import {graphql, useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import useQuery from '../utilities/use-query';
import i18n from '../utilities/i18n';
import {graphqlWhoamiNextQuery} from './graphql';
import {useForm} from 'react-hook-form';
import {submitForm} from '../utilities/utility';
import qs from 'qs';
import Boom from './boom';
import {length} from '../utilities/utility';
import useClaimStateEx from '../utilities/use-claim-state-ex';
import Button from './button/button';
import getIndependenceType, {PARTNER} from '../utilities/get-independence-type';
import CheckServiceState from './check-service-state';

const RAZZLE_APP_RETURN_URL = process.env.RAZZLE_APP_RETURN_URL;
const RAZZLE_APP_SERVER_CALLBACK_URL = process.env.RAZZLE_APP_SERVER_CALLBACK_URL;
const RAZZLE_APP_SERVICE_URL = process.env.RAZZLE_APP_SERVICE_URL;

const config = {shouldValidate: true, shouldDirty: true};

const Payment = React.memo(({register, pool, merchantAccount, merchantId, setValue}) => {
  const {poolsByViewer} = useLazyLoadQuery(
    graphql`
        query confirmPaymentComponentsPoolsByViewerQuery($request: PoolsRequestInput) {
            poolsByViewer(request: $request) {
                content {
                    id
                    cardPan
                    cardType
                    recToken
                }
            }
        }
    `,
    {request: {merchantAccount: merchantAccount, merchantId, size: 100500, page: 0}}
  );
  React.useEffect(() => {
    if (length(poolsByViewer.content)) {
      setValue('pool', poolsByViewer.content[0].recToken, config);
    } else {
      setValue('pool', '-1', config);
    }
  }, [poolsByViewer]);
  return (
    <>
    <div className='text-xl mw768-display-xs semibold color-gray-900'>
      Спосіб оплати
    </div>
    <div className='padding-top-1rem mw768-padding-top-1dot5rem'>
      {poolsByViewer.content.map((entry) =>
        <div key={entry.id} className='padding-bottom-1rem'>
          <label htmlFor={`confirm-pool-${entry.recToken}`}>
            <div className='border-1px-solid border-color-gray-300 background-color-gray-50 border-radius-0dot5rem padding-1rem cursor-pointer'>
              <div className='display-flex justify-content-space-between align-items-center'>
                <div className='display-flex align-items-center'>
                  {/Visa/i.test(entry.cardType) && 
                  <MasterCardSvg className='display-block height-2rem width-2dot8755rem'/>
                  }
                  {/MasterCard/i.test(entry.cardType) &&
                  <VisaSvg className='display-block height-2rem width-2dot8755rem'/>
                  }
                  <div className='padding-left-0dot5rem text-md medium color-gray-900'>
                    {entry.cardPan.replace(/X/g, '*')}
                  </div>
                </div>
                <div><input id={`confirm-pool-${entry.recToken}`} type='radio' value={entry.recToken} {...register('pool')}/></div>
              </div>
            </div>
          </label>
        </div>
      )}
      <label htmlFor={`confirm-pool-1`}>
        <div className='border-1px-solid border-color-gray-300 background-color-gray-50 border-radius-0dot5rem padding-1rem cursor-pointer'>
          <div className='display-flex justify-content-space-between align-items-center'>
            <div className='text-md medium color-gray-900'>
              Кредитна/Дебітна Картка
            </div>
            <div><input id='confirm-pool-1' type='radio' value='-1' {...register('pool')}/></div>
          </div>
          <div className='display-flex padding-top-0dot75rem'>
            <MasterCardSvg className='display-block height-2rem width-2dot8755rem'/>
            <VisaSvg className='margin-left-0dot5rem display-block height-2rem width-2dot8755rem'/>
            <ApplePaySvg className='margin-left-0dot5rem display-block height-2rem width-2dot8755rem'/>
            <GooglePaySvg className='margin-left-0dot5rem display-block height-2rem width-2dot8755rem'/>
          </div>
          <div className='padding-top-0dot75rem'>
            <label htmlFor='confirm-upsert'>
              <div className='display-flex align-items-center'>
                  <input id='confirm-upsert' type='checkbox' {...register('upsert')} disabled={pool !== '-1'}/>
                  <div className='padding-left-0dot75rem text-md color-gray-600'>
                    Зберегти платіжні дані
                  </div>
              </div>
            </label>
          </div>
        </div>
      </label>
    </div>
    </>
  );
});

const ConfirmImpl = React.memo(({claim}) => {
  const {locale = 'uk'} = useQuery();
  const navigate = useNavigate();
  const [paymentInProgress, setPaymentInProgress] = React.useState(false);
  const {impulseByClaim} = useLazyLoadQuery(
    graphql`
      query confirmConfirmImplQuery($claimRequest: ClaimRequestInput) {
          impulseByClaim(request: $claimRequest) {
              id
              orderDates
              amount
              impulseService {
                  service {
                      id
                      name
                      nameUk
                  }
                  independenceType
                  merchantAccount
                  partnerCode
                  merchantDomainName
                  holdTimeout
                  mrchntd
              }
              impulseEndpoints {
                  name
                  nameUk
                  price
                  quantity
              }
              impulseClient {
                  email
              }
              feeAmount
              amountAmount
              orderReferences
              merchantSignatures
          }
      }
    `,
    {claimRequest: {value: claim}}
  );
  const location = useLocation();
  const {whoamiNext} = useLazyLoadQuery(graphqlWhoamiNextQuery);
  const {register, watch, setValue} = useForm();
  const pool = watch('pool');
  const upsert = watch('upsert');
  React.useEffect(() => {
    if (pool !== '-1') {
      setValue('upsert', false, config);
    }
}, [pool]);
  const [sign, isSignInFlight] = useMutation(
    graphql`
        mutation confirmConfirmImplComponentsFondySignMutation($request: FondySignRequestInput) {
            fondySign(request: $request) {
                signature
                orderId
                reservationData
            }
        }
    `
  );
  const [recurring, isRecurringInFlight] = useMutation(
    graphql`
        mutation confirmConfirmImplComponentsFondyRecurringMutation($request: FondyRecurringRequestInput) {
            fondyRecurring(request: $request) {
                response {
                    responseStatus
                }
            }
        }
    `
  );
  const fondyPayFirstTime = () => {
    const payload = {
        merchant_id: impulseByClaim.impulseService.mrchntd,
        order_desc: impulseByClaim.impulseService.service.name,
        amount: impulseByClaim.amount.split('.').join(''),
        currency: 'UAH',
        ...(RAZZLE_APP_RETURN_URL && {response_url: `${RAZZLE_APP_RETURN_URL}?url=${encodeURIComponent(`${window.location.origin}/complete${searchFilter(location.search, {impulseId: impulseByClaim.id})}`)}`}),
        ...(RAZZLE_APP_SERVER_CALLBACK_URL && {
            server_callback_url: RAZZLE_APP_SERVER_CALLBACK_URL + qs.stringify({upsert}, {addQueryPrefix: true})
        }),
        lifetime: 900, // this also means hold 7 working days for preauth: 'Y'
        preauth: 'Y',
        lang: locale,
        sender_email: impulseByClaim.impulseClient.email,
        required_rectoken: upsert ? 'Y' : 'N',
        delayed: 'Y'
    };
    sign({
        variables: {request: {payload, claim: claim}},
        onCompleted: (data) => {
            submitForm('https://pay.fondy.eu/api/checkout/redirect/', {
                ...payload,
                signature: data.fondySign.signature,
                order_id: data.fondySign.orderId,
                reservation_data: data.fondySign.reservationData
            });
        },
        onError: (error) => {
          setPaymentInProgress(false);
        },
    });
  };
  const fondyPayRecurring = () => {
      const payload = {
          merchant_id: impulseByClaim.impulseService.mrchntd,
          order_desc: impulseByClaim.impulseService.service.name,
          amount: impulseByClaim.amount.split('.').join(''),
          currency: 'UAH',
          server_callback_url: 'https://a0.ottry.com/api/impulses/callback5',
          preauth: 'Y',
          sender_email: impulseByClaim.impulseClient.email,
          rectoken: pool
      };
      recurring({
          variables: {request: {payload, claim: claim}},
          onCompleted: () => {
              navigate(`/complete${searchFilter(location.search, {impulseId: impulseByClaim.id})}`);
          },
          onError: (error) => {
            setPaymentInProgress(false);
          },
      });
  };
  const wayforpayPay = () => {
    new window.Wayforpay().run({
        orderDate: impulseByClaim.orderDates &&
        impulseByClaim.orderDates[impulseByClaim.orderDates.length - 1],
        currency: 'UAH',
        merchantTransactionType: 'AUTH',
        productName: 'SERVICE',
        amount: impulseByClaim.amount,
        productPrice: impulseByClaim.amount,
        productCount: 1,
        ...(getIndependenceType(impulseByClaim.impulseService.independenceType) === PARTNER && {
            partnerCode: [impulseByClaim.impulseService.merchantAccount, impulseByClaim.impulseService.partnerCode],
            partnerPrice: [impulseByClaim.feeAmount, impulseByClaim.amountAmount]
        }),
        makeRedirect: true,
        language: locale === 'uk' ? 'ua' : locale,
        orderReference: impulseByClaim.orderReferences &&
        impulseByClaim.orderReferences[impulseByClaim.orderReferences.length - 1],
        merchantAccount: impulseByClaim.impulseService.merchantAccount,
        merchantDomainName: impulseByClaim.impulseService.merchantDomainName,
        merchantSignature: impulseByClaim.merchantSignatures &&
        impulseByClaim.merchantSignatures[impulseByClaim.merchantSignatures.length - 1],
        orderTimeout: 900,
        orderLifetime: 900,
        holdTimeout: impulseByClaim.impulseService.holdTimeout || 900, // or 15 minutes
        clientEmail: impulseByClaim.impulseClient.email,
        paymentSystems: 'card;googlePay;applePay;masterPass;visaCheckout;privat24', // paymentSystems: systemToString[system],
        ...(pool !== '-1' && {recToken: pool}),
        ...(RAZZLE_APP_SERVICE_URL && {serviceUrl: RAZZLE_APP_SERVICE_URL + (pool === '-1' ? qs.stringify({upsert}, {addQueryPrefix: true}) : '')}),
        ...(RAZZLE_APP_RETURN_URL && {returnUrl: `${RAZZLE_APP_RETURN_URL}?url=${encodeURIComponent(`${window.location.origin}/complete${searchFilter(location.search, {impulseId: impulseByClaim.id})}`)}`})
    });
    setPaymentInProgress(false);
  }
  const pay = () => {
    setPaymentInProgress(true);
    if (impulseByClaim.impulseService.merchantAccount) {
      wayforpayPay();
    } else {
      pool === '-1' ? fondyPayFirstTime() : fondyPayRecurring();
    }
  };
  return (
    <div className='padding-left-1dot25rem padding-right-1dot25rem'>
      <div className='mw768-max-width-41rem mw768-margin-0-auto mw768-padding-top-2rem'>
        <div className='card background-color-white padding-top-1dot5rem mw768-padding-top-2dot5rem mw768-padding-left-2rem mw768-padding-right-2rem mw768-padding-bottom-2rem position-relative'>
          <div className='display-sm mw768-display-md semibold color-gray-900'>
            Оплата
          </div>
          <div className='padding-top-1dot5rem mw768-padding-top-2rem'>
            <Payment {...{
              register,
              setValue,
              pool,
              merchantAccount: impulseByClaim.impulseService.merchantAccount,
              merchantId: impulseByClaim.impulseService.mrchntd
            }}/>
          </div>
          <div className='padding-top-1dot5rem mw768-padding-top-2rem'>
            <div className='color-gray-900 text-lg medium'>
              Аккаунт
            </div>
            <div className='display-flex justify-content-space-between'>
              <div className='color-gray-700 text-md display-flex justify-content-space-between'>
                {impulseByClaim.impulseClient.email}
              </div>
            </div>
          </div>
        </div>
        <div className='padding-top-2dot5rem mw768-padding-top-0'>
          <div className='display-none mw768-display-block background-color-gray-50 border-top-left-radius-0dot75rem border-top-right-radius-0dot75rem mw768-border-top-left-radius-0 mw768-border-top-right-radius-0 border-bottom-left-radius-0dot75rem border-bottom-right-radius-0dot75rem padding-top-1dot25rem mw768-padding-top-2rem padding-bottom-1dot25rem mw768-padding-bottom-2rem padding-left-1dot25rem mw768-padding-left-0 padding-right-1dot25rem mw768-padding-right-0'>
            <div className='display-flex justify-content-center align-items-center'>
              <LockUnlocked03Svg className='display-block height-1dot5rem width-1dot5rem color-gray-600'/>
              <div className='padding-left-0dot75rem text-md color-gray-500'>
                Замовлення ви можете забрати одразу після оплати, або пізніше
              </div>
            </div>
          </div>
          <div className='display-block mw768-display-none height-0dot5rem background-color-gray-200 margin-0-minus1dot25rem'>
          </div>
        </div>
        <div className='mw768-padding-top-2rem'>
          <div className='mw768-max-width-37rem mw768-margin-0-auto mw768-border-1px-solid mw768-border-color-gray-200 mw768-border-radius-0dot75rem'>
            <div className='padding-bottom-1dot5rem mw768-padding-1dot5rem'>
              <div className='padding-top-1dot5rem mw768-padding-top-0'>
                <div className='text-xl semibold color-gray-900'>Ваше замовлення</div>
                <div className='padding-top-0dot5rem'>
                  {impulseByClaim.impulseEndpoints.map((entry, index) =>
                    <div key={index}>
                      <div className='padding-top-1rem padding-bottom-1rem'>
                        <div className='display-flex'>
                          <div className='flex-1'>
                            <div className='display-flex'>
                              <div className='text-md medium color-gray-700'>
                                {i18n(entry, 'name', locale)}
                              </div>
                              
                            </div>
                          </div>
                          <div className='align-self-flex-end text-md color-gray-700 padding-left-0dot5rem width-6dot375rem text-align-right'>
                            <span className='padding-right-0dot25rem'>
                              {entry.quantity} x
                            </span>
                            <span>
                              {entry.price} ₴
                            </span>                
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className='padding-top-1dot5rem'>
                  <div className='height-0dot0625rem background-color-gray-200'>&nbsp;</div>
                </div>
                <div className='padding-top-1rem'>
                  <div className='display-flex justify-content-space-between'>
                    <div className='text-lg semibold color-gray-700'>
                      До сплати
                    </div>
                    <div className='text-lg semibold color-gray-700'>
                      {impulseByClaim.amount} ₴
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='padding-top-1dot25rem mw768-padding-top-2rem padding-bottom-1dot25rem mw768-padding-bottom-2rem'>
          <div className='mw768-max-width-37rem mw768-margin-0-auto'>
            <Button {...{color: 'orange-gradient', size: '2xl', clickHandler: () => pay(), fluid: 'always', isLoading: paymentInProgress}}>
              Оплатити
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
});

const Confirm = React.memo(() => {
  const {impulseId} = useQuery();
  const claimState = useClaimStateEx();
  const value = React.useMemo(() => claimState.find(e => e.id === impulseId), [claimState, impulseId]);
  return (
    <>
    {value ? 
      <ConfirmImpl {...{claim: value.claim}}/>
      :
      <>Your confirm is empty</>
    }
    </>
  );
});

export default React.memo(() => {
  return (
    <>
    <Helmet>
        <title>Welcome to Time to eat confirm</title>
        <meta name='description' content='Web site of your dream'/>
    </Helmet>
    <ErrorBoundary {...{FallbackComponent: Boom}}>
      <div className='check-out'>
        <div className='top'>
          <div className='mw768-max-width-80rem mw768-margin-0-auto'>
            <Top/>
            <Browser>
              <React.Suspense fallback={
                <div className='padding-left-1dot25rem padding-right-1dot25rem'>
                  <div className='mw768-max-width-41rem mw768-margin-0-auto mw768-padding-top-2rem'>
                    <div className='card background-color-white padding-top-1dot5rem mw768-padding-top-2dot5rem mw768-padding-left-2rem mw768-padding-right-2rem mw768-padding-bottom-2rem position-relative'>
                      <Skeleton/>
                    </div>
                  </div>
                </div>
              }>
                <ErrorBoundary {...{FallbackComponent}}>
                  <Confirm/>
                  <CheckServiceState/>
                </ErrorBoundary>
              </React.Suspense>
            </Browser>
          </div>
        </div>
      </div>
    </ErrorBoundary>
    </>
  );
});

