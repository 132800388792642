import React from 'react';
import './check-out.scss';
import {ErrorBoundary} from 'react-error-boundary';
import {Helmet} from 'react-helmet-async';
import {Link, useLocation} from 'react-router-dom';
import FallbackComponent from './fallback-component';
import Skeleton from './skeleton/skeleton';
import searchFilter from '../utilities/search-filter';
import LockUnlocked03Svg from '../assets/icons0/lock-unlocked-03.svg';
import {useAuthorizationState} from './authorization';
import SignUp from './sign-up';
import Top from './top';
import classNames from 'classnames';
import Browser from './browser';
import {useCartDispatch} from './cart';
import {graphql, useLazyLoadQuery, useFragment, useMutation} from 'react-relay/hooks';
import useQuery from '../utilities/use-query';
import i18n from '../utilities/i18n';
import SignIn from './sign-in';
import useMountedState from '../utilities/use-mounted-state';
import Redirect from './redirect';
import {graphqlWhoamiNextQuery} from './graphql';
import {useClaimDispatch} from './claim';
import Boom from './boom';
import PlusSvg from '../assets/icons0/plus.svg';
import UseFormAddlInput from './use-form-addl-input/use-form-addl-input';
import {useForm} from 'react-hook-form';
import {length} from '../utilities/utility';
import bag from '../utilities/bag';
import useCartStateEx from '../utilities/use-cart-state-ex';
import Button from './button/button';
import XCloseSvg from '../assets/icons0/x-close.svg';
import CartCheck from './cart-check';
import CheckServiceState from './check-service-state';

const RAZZLE_GRECAPTCHA = process.env.RAZZLE_GRECAPTCHA;

const Pick = React.memo(({entry}) => {
  const {locale = 'uk'} = useQuery();
  const {endpointById, serviceById} = useLazyLoadQuery(
    graphql`
        query checkOutPickComponentsEndpointByIdServiceByIdQuery($endpointId: String, $serviceId: String) {
            endpointById(id: $endpointId) {
                id
                name
                nameUk
                price
            }
            serviceById(id: $serviceId) {
                id
                name
                nameUk
            }
        }
    `,
    {endpointId: entry.endpointId, serviceId: entry.serviceId}
  );
  const cartDispatch = useCartDispatch();
  return (
    <>
    <div className='padding-top-1rem padding-bottom-1rem'>
      <div className='display-flex'>
        <div className='flex-1'>
          <div className='display-flex'>
            <div className={classNames('text-md medium', {'color-gray-300': entry.unavailable, 'color-gray-700': !entry.unavailable})}>
              {i18n(endpointById, 'name', locale)}
            </div>
          </div>
          <div className='padding-top-0dot25rem'>
            <div 
              className='cursor-pointer display-flex align-items-center'
              onClick={() => {
                cartDispatch({
                  type: 'substract', 
                  payload: {endpointId: endpointById.id, serviceId: serviceById.id, quantity: entry.quantity}
                });
              }}
            >
              <XCloseSvg className='display-block height-0dot75rem width-0dot75rem color-gray-500'/>
              <div className='padding-left-0dot25rem text-xs medium color-gray-500'>
                Видалити
              </div>
            </div>
          </div>
        </div>
        <div className={classNames('align-self-flex-end padding-bottom-1dot625rem text-md padding-left-0dot5rem width-6dot375rem text-align-right', {'color-gray-300': entry.unavailable, 'color-gray-700': !entry.unavailable})}>
          <span className='padding-right-0dot25rem'>
            {entry.quantity} x
          </span>
          <span>
            {endpointById.price} ₴
          </span>
        </div>
      </div>
    </div>
    </>
  );
});

const ContactImpl = React.memo(() => {
  const {whoamiNext} = useLazyLoadQuery(graphqlWhoamiNextQuery);
  const [flag, setFlag] = React.useState();
  return (
    <>
    {flag && <div className='display-none'><SignIn/></div>}
    <div className='text-xl semibold color-gray-900'>
      Контакні дані
    </div>
    <div className='padding-top-1dot5rem text-sm medium color-gray-700'>
      Аккаунт
    </div>
    <div className='padding-top-0dot375rem display-flex justify-content-space-between'>
      <div className='text-md color-gray-900'>
        {whoamiNext.username || whoamiNext.email}
      </div>
      <div 
        className='text-md medium color-orange-600 cursor-pointer '
        onClick={() => {setFlag(true)}}
      >
        Змінити
      </div>
    </div>
    </>
  );
});

const Contact = React.memo(({serviceId, register, errors, addls}) => {
  const {serviceById} = useLazyLoadQuery(
    graphql`
        query checkOutContactComponentsQuery($id: String) {
            serviceById(id: $id) {
                id
                addls {
                    id
                    ...useFormAddlInputClose_addl
                }
            }
        }
    `,
    {id: serviceId}
  );
  return (
    <>
    <ContactImpl/>
    {length(serviceById.addls) && serviceById.addls.map(e => 
      <div key={e.id} className={classNames('padding-top-1dot5rem')}>
        <UseFormAddlInput {...{ 
          register, 
          addl: e, 
          error: errors.addls && errors.addls[e.id]
        }}/>
      </div>
    )}
    </>
  );
});

const Create = React.memo(({children, picks, serviceId, setTo, handleSubmit, setHasCreateImpulseError}) => {
  const {locale = 'uk'} = useQuery();
  const location = useLocation();
  const cartDispatch = useCartDispatch();
  const claimDispatch = useClaimDispatch();
  const {whoamiNext} = useLazyLoadQuery(graphqlWhoamiNextQuery);
  const [grecaptcha, setGrecaptcha] = useMountedState();
  const [commit, isMutationInFlight] = useMutation(
    graphql`
        mutation checkOutCreateComponentsMutation($impulseRequest: ImpulseRequestInput!) {
            createImpulse(impulseRequest: $impulseRequest) {
                id
                claim
            }
        }
    `);
    const submit = handleSubmit(data => {
      setHasCreateImpulseError(false);
      bag.set(data);
      setGrecaptcha(true);
      window.grecaptcha.ready(() => {
          window.grecaptcha.execute(RAZZLE_GRECAPTCHA, {action: 'submit'}).then((token) => {
              commit({
                  variables: {
                      impulseRequest: {
                          locale,
                          email: whoamiNext.username || whoamiNext.email,
                          reCaptcha: token,
                          service: serviceId,
                          impulseEndpoints: picks.map((item) => ({
                              endpoint: item.endpointId,
                              quantity: item.quantity
                          })),
                          impulseAddls: data.addls && Object.entries(data.addls)
                            .map(([key, value]) => ({
                                addl: key,
                                value: value === true ? '+' : value === false ? '-' : value
                            }))
                      }
                  },
                  onCompleted: ({createImpulse}) => {
                    claimDispatch({type: 'add', payload: {id: createImpulse.id, claim: createImpulse.claim, serviceId}});
                    cartDispatch({type: 'bulkRemove', payload: picks.map(item => item.key)});
                    setTo(`/confirm${searchFilter(location.search, {impulseId: createImpulse.id})}`);
                  },
                  onError: (error) => {
                    if (/Endpoint invalid/i.test(error)) {
                      setHasCreateImpulseError(true);
                    }
                  }
              });
              setGrecaptcha(false);
          });
      });
    });
  return (
    <>{children(submit, grecaptcha || isMutationInFlight)}</>
  );
});

const OrderImpl = React.memo(({picks, serviceId, amount, setTo}) => {
  const [addls, _] = React.useState(() => bag.get().addls);
  const location = useLocation();
  const authorizationState = useAuthorizationState();
  const [flag, setFlag] = React.useState();
  const {register, handleSubmit, formState: {errors}} = useForm({defaultValues: {addls}});
  const [hasCreateImpulseError, setHasCreateImpulseError] = React.useState(false);
  const cartDispatch = useCartDispatch();
  return (
    <>
    {flag && <div className='display-none'><SignUp/></div>}
    {/* <form onSubmit={submit}> */}
      <div className='padding-left-1dot25rem padding-right-1dot25rem'>
        <div className='mw768-max-width-41rem mw768-margin-0-auto mw768-padding-top-2rem'>
          <div className='card background-color-white padding-top-1dot5rem mw768-padding-top-2dot5rem mw768-padding-left-2rem mw768-padding-right-2rem mw768-padding-bottom-2rem position-relative'>
            <div className='display-sm mw768-display-md semibold color-gray-900'>
              Оформлення
            </div>
            <div className='padding-top-1dot5rem mw768-padding-top-2rem'>
              {authorizationState ? 
                <Contact {...{serviceId, register, errors, addls}}/>
                :
                <div className='border-1px-solid border-color-gray-200 border-radius-0dot75rem padding-top-1dot5rem padding-bottom-1dot5rem padding-left-1dot25rem padding-right-1dot25rem'>
                  <div className='text-md mw768-text-lg medium color-gray-700'>
                      Щоб оформити замовлення, будь ласка, зареєструйтесь або увійдіть до системи
                  </div>
                  <div className='padding-top-1dot5rem'>
                    <div
                      style={{
                        background: 'linear-gradient(88.92deg, #EC4A0A 0%, #FB6514 100%)',
                        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)'
                      }}
                      className='cursor-pointer border-radius-0dot5rem padding-1rem'
                      onClick={() => {setFlag(true)}}
                    >
                      <div className='display-flex justify-content-center text-md medium color-white display-flex justify-content-center'>
                        Створити акаунт
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
          <div className='padding-top-2dot5rem mw768-padding-top-0'>
            <div className='display-none mw768-display-block background-color-gray-50 border-top-left-radius-0dot75rem border-top-right-radius-0dot75rem mw768-border-top-left-radius-0 mw768-border-top-right-radius-0 border-bottom-left-radius-0dot75rem border-bottom-right-radius-0dot75rem padding-top-1dot25rem mw768-padding-top-2rem padding-bottom-1dot25rem mw768-padding-bottom-2rem padding-left-1dot25rem mw768-padding-left-0 padding-right-1dot25rem mw768-padding-right-0'>
              <div className='display-flex justify-content-center align-items-center'>
                <LockUnlocked03Svg className='display-block height-1dot5rem width-1dot5rem color-gray-600'/>
                <div className='padding-left-0dot75rem text-md color-gray-500'>
                  Замовлення ви можете забрати одразу після оплати, або пізніше
                </div>
              </div>
            </div>
            <div className='display-block mw768-display-none height-0dot5rem background-color-gray-200 margin-0-minus1dot25rem'>
            </div>
          </div>
          <ErrorBoundary {...{FallbackComponent}}>
            <React.Suspense fallback={
              <div className='mw768-padding-top-2rem'>
                <div className='mw768-max-width-37rem mw768-margin-0-auto mw768-border-1px-solid mw768-border-color-gray-200 mw768-border-radius-0dot75rem'>
                  <div className='padding-bottom-1dot5rem mw768-padding-1dot5rem'>
                    <div className='padding-top-1dot5rem mw768-padding-top-0'>
                      <div className='display-flex justify-content-space-between align-items-center'>
                        <div className='text-xl semibold color-gray-900'>Ваше замовлення</div>
                          <Link to={`/${searchFilter(location.search)}`}>
                            <div className='display-flex align-items-center'>
                              <PlusSvg className='display-block width-1dot25rem height-1dot25rem color-orange-600'/>
                              <div className='padding-left-0dot5rem text-md medium color-orange-600'>
                                Додати
                              </div>
                            </div>
                          </Link>
                      </div>
                      <div className='padding-top-0dot5rem'>
                        <Skeleton/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }>
              <CartCheck {...{cartDispatch, cartState: picks, serviceId, update: hasCreateImpulseError}}>
                {({hasUnavailableEndpoints, cartChecked}) =>
                  {
                    return <>
                      <div className='mw768-padding-top-2rem'>
                        <div className='mw768-max-width-37rem mw768-margin-0-auto mw768-border-1px-solid mw768-border-color-gray-200 mw768-border-radius-0dot75rem'>
                          <div className='padding-bottom-1dot5rem mw768-padding-1dot5rem'>
                            <div className='padding-top-1dot5rem mw768-padding-top-0'>
                              <div className='display-flex justify-content-space-between align-items-center'>
                                <div className='text-xl semibold color-gray-900'>Ваше замовлення</div>
                                  <Link to={`/${searchFilter(location.search)}`}>
                                    <div className='display-flex align-items-center'>
                                      <PlusSvg className='display-block width-1dot25rem height-1dot25rem color-orange-600'/>
                                      <div className='padding-left-0dot5rem text-md medium color-orange-600'>
                                        Додати
                                      </div>
                                    </div>
                                  </Link>
                              </div>
                              <div className='padding-top-0dot5rem'>
                                {cartChecked.map((entry, index) => <Pick key={index} {...{entry}}/>)}
                              </div>
                              <div className='padding-top-1dot5rem'>
                                <div className='height-0dot0625rem background-color-gray-200'>&nbsp;</div>
                              </div>
                              <div className='padding-top-1rem'>
                                <div className='display-flex justify-content-space-between'>
                                  <div className='text-lg semibold color-gray-700'>
                                    До сплати
                                  </div>
                                  <div className='text-lg semibold color-gray-700'>
                                    {amount} ₴
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {authorizationState && 
                      <div className='padding-top-1dot25rem mw768-padding-top-2rem padding-bottom-1dot25rem mw768-padding-bottom-2rem'>
                        <div className='mw768-max-width-37rem mw768-margin-0-auto'>
                        <Create {...{picks, serviceId, setTo, handleSubmit, setHasCreateImpulseError}}>
                            {(submit, inFlight) => 
                              <Button {...{color: 'orange-gradient', size: '2xl', clickHandler: () => submit(), isLoading: inFlight, fluid: 'always', disabled: hasUnavailableEndpoints}}>
                                Обрати спосіб оплати
                              </Button>
                            }
                          </Create>
                        </div>
                      </div>
                      }
                    </>
                  }
                }
              </CartCheck>
            </React.Suspense>
          </ErrorBoundary>
        </div>
      </div>
    {/* </form> */}
    </>
  );
});

const Order = React.memo(({setTo}) => {
  const cartState = useCartStateEx();
  const cartStateDeferred = React.useDeferredValue(cartState);
  const request = React.useMemo(() => ({
      picks: cartStateDeferred.map((e) => ({endpoint: e.endpointId, service: e.serviceId, quantity: e.quantity}))
  }), [cartStateDeferred]);
  const {landImpulse} = useLazyLoadQuery(
      graphql`
          query checkOutQuery($request: LandRequestInput) {
              landImpulse(request: $request) {
                  lands {
                      service
                      pickExs {
                          index
                      }
                  }
                  solves {
                      amount
                  }
              }
          }
      `,
      {request}
  );
  const {landId} = useQuery();
  const land = landImpulse.lands[landId];
  const picks = React.useMemo(
      () =>  land ? land.pickExs.map(e => cartStateDeferred[e.index]) : [],
      [cartStateDeferred, land]
  );
  const service = land && land.service;
  const amount = land && landImpulse.solves[landId].amount;
  return (
      <div className={classNames({'opacity-0dot4': cartState !== cartStateDeferred})}>
        <OrderImpl {...{picks, serviceId:service, amount, setTo}}/>
      </div>
  );
});

const OrderEx = React.memo(() => {
  const [to, setTo] = React.useState();
  const cartState = useCartStateEx();
  const location = useLocation()
  if (to) {
    return <Redirect to={to} replace/>;
  }
  return (
    <>
    {!!cartState.length ?
      <Order {...{setTo}}/>
      :
      <Redirect to={`/${searchFilter(location.search)}`} />
    }
    </>
  );
});

export default React.memo(() => {
  return (
    <>
    <Helmet>
        <title>Welcome to Time to eat check out</title>
        <meta name='description' content='Web site of your dream'/>
    </Helmet>
    <ErrorBoundary {...{FallbackComponent: Boom}}>
      <div className='check-out'>
        <div className='top'>
          <div className='mw768-max-width-80rem mw768-margin-0-auto'>
            <Top/>
            <Browser>
              <React.Suspense fallback={
                <div className='padding-left-1dot25rem padding-right-1dot25rem'>
                  <div className='mw768-max-width-41rem mw768-margin-0-auto mw768-padding-top-2rem'>
                    <div className='card background-color-white padding-top-1dot5rem mw768-padding-top-2dot5rem mw768-padding-left-2rem mw768-padding-right-2rem mw768-padding-bottom-2rem position-relative'>
                      <Skeleton/>
                    </div>
                  </div>
                </div>
              }>
                <ErrorBoundary {...{FallbackComponent}}>
                  <OrderEx/>
                  <CheckServiceState/>
                </ErrorBoundary>
              </React.Suspense>
            </Browser>
          </div>
        </div>
      </div>
    </ErrorBoundary>
    </>
  );
});

