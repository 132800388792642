import {graphql} from 'react-relay/hooks';

export const graphqlOperatorInterfaceServiceByIdQuery = graphql`
    query graphqlOperatorInterfaceServiceByIdQuery($id: String) {
        serviceById(id: $id) {
            id
            name
            endpoints {
                id
                name
                nameUk
                details
                tags
                quantity
                end
                version
            }
        }
    }
`;