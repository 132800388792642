import Button from '../button/button';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import moment from 'moment';
import ArrowLeft from '../../assets/icons0/arrow-left.svg';
import StepsBar from './steps-bar';
import React from 'react';
import useQuery from '../../utilities/use-query';
import {useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import {graphqlOperatorInterfaceServiceByIdQuery} from './graphql';
import {useOperatorDispatch, useOperatorState} from './operator-interface';
import searchFilter from '../../utilities/search-filter';
import getTagValue from '../../utilities/get-tag-value';
import {useErrorMessagesDispatch} from '../error-messages/error-messages-context';
import RefreshCw01 from '../../assets/icons0/refresh-cw-01.svg'
import classNames from 'classnames';
import i18n from '../../utilities/i18n';
import useMountedState from '../../utilities/use-mounted-state';
import {graphqlWhoamiNextQuery} from '../graphql';
import {timeZone} from '../../utilities/utility';

const serviceIdEx = process.env.RAZZLE_APP_SERVICE_ID;
const RAZZLE_GRECAPTCHA = process.env.RAZZLE_GRECAPTCHA;
const TEMPORARY_MM_EMAIL = 'olchert4@gmail.com';

const EndpointRow = React.memo(({item, endpointsState}) => {
  const {locale = 'uk'} = useQuery();
  const endpointState = endpointsState[item.id] || {};
  const income = endpointState.income || 0;
  const inventory = endpointState.inventory || 0;
  const expired = isNaN(endpointState.expired) ? 0 : endpointState.expired;
  const outcome = isNaN(endpointState.outcome) ? 0 : endpointState.outcome;
  return (
    <div className='table-row display-flex justify-content-space-between border-bottom'>
      <div className='left-column'>
        <div className='text-sm medium color-gray-900'>
          {i18n(item, 'name', locale)}
        </div>
        <div className='text-lg color-gray-500 margin-top-0dot5rem'>
          {endpointState.quantity || item.quantity}
          {(!!expired || !!outcome) &&
            <span className='color-gray-500 regular margin-left-0dot375rem'>
              (-{expired + outcome})
            </span>
          }
        </div>
      </div>
      <div className='display-flex width-auto text-align-center margin-top-2rem align-items-flex-end'>
        <span className={classNames('text-lg', {'color-gray-400' : !inventory, 'color-success-600': inventory > 0, 'color-error-600': inventory < 0})}>
          {inventory || '-'}
        </span>
      </div>
      <div className='color-gray-500 display-flex right-column text-align-right flex-direction-column justify-content-space-between'>
        <div className='item-date text-sm'>
          {endpointState.end && moment(endpointState.end).format('DD.MM.YYYY')}
        </div>
        <div className='text-lg margin-top-0dot625rem'>
          {endpointState.income &&
            <span className='color-gray-500 regular margin-right-0dot375rem'>
              (+{endpointState.income})
            </span>
          }
          <span className='color-gray-900 medium'>
            {(endpointState.quantity || item.quantity) + (inventory > 0 ? 0 : inventory) + income - expired - outcome}
          </span>
        </div>
      </div>
    </div>
  )
});

const ReportHeader = React.memo(({serviceById, report}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useOperatorDispatch();
  const {serviceId = serviceIdEx, locale = 'uk'} = useQuery();
  const errorDispatch = useErrorMessagesDispatch();
  const {whoamiNext} = useLazyLoadQuery(graphqlWhoamiNextQuery);
  const endpointsState = report.endpoints;
  const [updateEndpointSeason, updatingEndpointSeason] = useMutation(
    graphql`
        mutation step8OperatorInterfaceEndpointSeasonMutation($request: UpdateEndpointSeasonRequestInput) {
            updateEndpointSeason(request: $request) {
                id
                version
                start
                end
                strategy
                every
            }
        }
  `);
  const updateSeason = (id, version, end, endpointEnd) => {
    const endpointHasEnd = !!endpointEnd;
    const endUTC = moment(end).utc().format();
    const shouldUpdate = end && ((endUTC && !endpointHasEnd) || (endpointHasEnd && endUTC !== moment(endpointEnd).utc().format()));
    if (shouldUpdate) {
      updateEndpointSeason({
        variables: {request: {
            id,
            version,
            start: null,
            end: endUTC,
            strategy: null,
            every: null
        }},
        onCompleted: () => {},
        onError: (error) => {
          setUpdateError(true);
          setUpdateStep('end');
        }
      })
    }
  };
  const [updateEndpointTags, updatingEndpointTags] = useMutation(
    graphql`
        mutation step8OperatorInterfaceEndpointTagsMutation($request: UpdateEndpointTagsRequestInput) {
            updateEndpointTags(request: $request) {
                id
                tags
                version
            }
        }
  `);
  const updateTags = (id, version, tags, mmDisplayValue) => {
    const currentEndpointValue = getTagValue(tags, 'mmDisplay', ':', false) === 'true';
    if (mmDisplayValue !== currentEndpointValue) {
      const tagsArray = tags ? tags.filter(tag => !tag.startsWith('mmDisplay')) : [];
      if (mmDisplayValue) {
        tagsArray.push(`mmDisplay:${mmDisplayValue}`);
      }
      updateEndpointTags({
        variables: {request: {
          id: id,
          version: version,
          tags: tagsArray
        }},
        onCompleted: () => {},
        onError: (error) => {
          setUpdateError(true);
          setUpdateStep('end');
        }
      })
    }
  };
  const [adjustEndpointQuantity, adjustingEndpointQuantity] = useMutation(
    graphql`
        mutation step8OperatorInterfaceAdjustEndpointQuantityMutation($request: AdjustEndpointQuantityRequestInput) {
          adjustEndpointQuantity(request: $request) {
                id
                quantity
                version
            }
        }
  `);
  const adjustQuantity = (id, quantity) => {
    adjustEndpointQuantity({
      variables: {request: {
          id,
          quantity
      }},
      onCompleted: ({adjustEndpointQuantity}) => {
        const selectedEndpointState = endpointsState[adjustEndpointQuantity.id];
        dispatch({type: 'update-endpoint',
        payload: {
          serviceId,
          endpoint: {
            ...selectedEndpointState,
            adjusted: true
          }
        }})
        
      },
      onError: (error) => {
        setUpdateError(true);
        setUpdateStep('end');
      }
    })
  };
  const [hasUpdateError, setUpdateError] = React.useState(false);
  const [updateStep, setUpdateStep] = React.useState('');
  React.useEffect(() => {
    if (updateStep === 'start') {
      startSeasonUpdate();
    } else if (updateStep === 'end') {
      if (hasUpdateError) {
        setUpdateStep('');
        errorDispatch({
          type: 'add',
          payload: {
            title: 'Помилка надсилання звіту',
            message: 'Сталася помилка під час надсилання звіту про інвентаризацію',
            action: <Button {...{color: 'error', plain: true, size: 'sm', clickHandler: sendReport}}>Повторити відправку <RefreshCw01 className='display-block height-1dot25rem width-1dot25rem margin-left-0dot5rem'/></Button>
          }
        });
      } else {
        completeReport();
      }
    }
  }, [hasUpdateError, updateStep]);

  React.useEffect(() => {
    if (updatingEndpointSeason) {
      if (updateStep !== 'seasonUpdating') {
        setUpdateStep('seasonUpdating');
      }
    } else if (updateStep === 'seasonUpdating' && !hasUpdateError) {
      startQuantityUpdate();
    }
  }, [updateStep, updatingEndpointSeason, hasUpdateError]);

  React.useEffect(() => {
    if (adjustingEndpointQuantity) {
      if (updateStep !== 'quantityAdjusting') {
        setUpdateStep('quantityAdjusting');
      }
    } else if (updateStep === 'quantityAdjusting' && !hasUpdateError) {
      startTagsUpdate();
    }
  }, [updateStep, adjustingEndpointQuantity, hasUpdateError]);

  React.useEffect(() => {
    if (updatingEndpointTags) {
      if (updateStep !== 'tagsUpdating') {
        setUpdateStep('tagsUpdating');
      }
    } else if (updateStep === 'tagsUpdating' && !hasUpdateError) {
      setUpdateStep('end');
    }
  }, [updateStep, updatingEndpointTags, hasUpdateError]);

  const sendReport = () => {
    setUpdateError(false);
    errorDispatch({type: 'reset', payload: []});
    setUpdateStep('start');
  };

  const startSeasonUpdate = () => {
    const endpointsUpdateSeason = serviceById.endpoints.filter(e => endpointsState[e.id] && !!endpointsState[e.id].end && moment(endpointsState[e.id].end).utc().format() !== e.end);
    setUpdateStep('season');
    if (endpointsUpdateSeason.length) {
      endpointsUpdateSeason.forEach(endpoint => {
        updateSeason(endpoint.id, endpoint.version, endpointsState[endpoint.id].end, endpoint.end);
      });
    } else {
      startQuantityUpdate();
    }
  };
  const startQuantityUpdate = () => {
    const endpointsArray = Object.values(endpointsState);
    const endpointsAdjustQuantity = endpointsArray.filter(e => (!!e.income || (!!e.inventory && e.inventory < 0) || !!e.expired || !!e.outcome) && !e.adjusted);
    setUpdateStep('quantity');
    if (endpointsAdjustQuantity.length) {
      endpointsAdjustQuantity.forEach(endpoint => {
        const income = endpoint.income || 0;
        const inventory = !endpoint.inventory ? 0 : endpoint.inventory > 0 ? 0 : endpoint.inventory;
        const expired = isNaN(endpoint.expired) ? 0 : endpoint.expired;
        const outcome = isNaN(endpoint.outcome) ? 0 : endpoint.outcome;
        if (income || inventory || outcome || expired) {
          adjustQuantity(endpoint.id, income + inventory - outcome - expired);
        }
      });
    } else {
      startTagsUpdate();
    }
  };
  const startTagsUpdate = () => {
    setUpdateStep('tags');
    const endpointsUpdateTags = serviceById.endpoints.filter(e => (!e.quantity && getTagValue(e.tags, 'mmDisplay', ':', false)) || (e.quantity && !getTagValue(e.tags, 'mmDisplay', ':', false)));
    if (endpointsUpdateTags.length) {
      endpointsUpdateTags.forEach(endpoint => {
        updateTags(endpoint.id, endpoint.version, endpoint.tags, !!endpoint.quantity);
      });
    } else {
      setUpdateStep('end');
    }
  };
  const [grecaptcha, setGrecaptcha] = useMountedState();
  const [mmCreateReport, mmCreatingReport] = useMutation(
    graphql`
        mutation step8OperatorInterfaceMmCreateReportMutation($request: MmReportRequestInput) {
          mmCreateReport(request: $request)
        }
  `);

  const completeReport = () => {
    setGrecaptcha(true);
    const reportDate = moment();
    const date = reportDate.utc().format();
    const timezone = timeZone();
    const records = Object.keys(report.endpoints).map(id => {
      const {income, outcome, expired, inventory} = report.endpoints[id];
      return {
        ednpointId: id,
        income,
        outcome,
        expired,
        inventory
      };
    });
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(RAZZLE_GRECAPTCHA, {action: 'submit'}).then((token) => {
        mmCreateReport({
          variables: {
              request: {
                locale,
                // email: whoamiNext.username || whoamiNext.email,
                email: TEMPORARY_MM_EMAIL,
                reCaptcha: token,
                serviceId: serviceId,
                inStock: [...report.stockListIds],
                date,
                records,
                timeZone: timezone,
                uuid: report.key
              }
          },
          onCompleted: (data) => {
            dispatch({type: 'complete', payload: {serviceId}});
            setUpdateStep('');
            navigate(`../complete${searchFilter(location.search)}`);
          },
          onError: (error) => {
            setUpdateError(true);
            setUpdateStep('end');
          }
        });
        setGrecaptcha(false);
      });
    });
  };
  return (
    <>
      <div className='mm-header background-color-gray-50'>
        <div className='width-2dot5rem height-2dot5rem'></div>
        <StepsBar/>
        <Link to={`../step-7${searchFilter(location.search)}`}>
          <ArrowLeft className='display-block color-gray-500 height-1dot5rem'/>  
        </Link>
      </div>
      <div className='button-block-padding background-color-gray-50'>
        <div className='text-2xl medium color-gray-900'>Формування звіту</div>
        <div className='text-md color-gray-500 margin-top-0dot25rem'>{moment().locale('uk').format('DD MMMM YYYY ')} р.</div>
        <div className='display-flex gap-12 margin-top-1rem'>
          <Button
            {...{
              color: 'blue-gradient',
              size: 'lg',
              clickHandler: sendReport,
              isLoading: updateStep || mmCreatingReport,
              disabled: false
            }}
          >
            Надіслати звіт
          </Button>
        </div>
      </div>
    </>
  )
});

const CompletedReportHeader = React.memo(() => {
  const {serviceId = serviceIdEx, reportIndex} = useQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const state = useOperatorState().completedReports[serviceId][reportIndex];
  const exportReport = () => {
    // navigate(`../complete${searchFilter(location.search)}`);
  };
  return (
    <>
      <div className='mm-header background-color-gray-50'>
        <div className='width-2dot5rem height-2dot5rem'></div>
        <StepsBar/>
        <Link to={`/${searchFilter(location.search)}`}>
          <ArrowLeft className='display-block color-gray-500 height-1dot5rem'/>  
        </Link>
      </div>
      <div className='button-block-padding background-color-gray-50'>
        <div className='text-2xl medium color-gray-900'>Звіт</div>
        <div className='text-md color-gray-500 margin-top-0dot25rem'>{moment(state.date).locale('uk').format('DD MMMM YYYY')} р.</div>
        <div className='display-flex gap-12 margin-top-1rem'>
          <Button
            {...{
              color: 'blue-gradient',
              size: 'lg',
              clickHandler: exportReport,
              isLoading: false,
              disabled: false
            }}
          >
            Експортувати
          </Button>
        </div>
      </div>
    </>
  )
});

const Step8 = () => {
  const {serviceId = serviceIdEx, reportIndex} = useQuery();
  const {serviceById} = useLazyLoadQuery(graphqlOperatorInterfaceServiceByIdQuery, {id: serviceId});
  const endpoints = React.useMemo(
    () => serviceById.endpoints.slice().sort((a, b) => getTagValue(a.tags, 'rank', ':', Infinity) - getTagValue(b.tags, 'rank', ':', Infinity)), 
  [serviceById]);
  const state = useOperatorState();
  const report = !reportIndex ? state[serviceId] : state.completedReports[serviceId][reportIndex];
  const endpointsState = report.endpoints;
  return (
    <>
      {!!reportIndex && <CompletedReportHeader/>}
      {!reportIndex && <ReportHeader {...{serviceById, report}}/>}
      <div>
        <div className='report'>
          <div className='thead display-flex background-color-gray-50 border-bottom justify-content-space-between'>
            <div className='thead-left text-xs color-gray-500 left-column'>
              Було
            </div>
            <div className='thead-center text-xs color-gray-500 text-align-center width-auto'>
              Різниця
            </div>
            <div className='thead-right text-xs color-gray-500 text-align-right right-column'>
              Стало
            </div>
          </div>
          {endpoints.map(item => 
            <EndpointRow key={item.id} {...{item, endpointsState}}/>
          )}
        </div>
      </div>
    </>
  );
};

export default React.memo(Step8);