import React from 'react';
import IconOperatorInterfaceSuccess from '../../assets/operator-interface-success.svg';
import Button, {RouterLinkButton} from '../button/button';
import IconArrowBlockDown from '../../assets/icons0/arrow-block-down.svg';
import {useLocation} from 'react-router-dom';
import searchFilter from '../../utilities/search-filter';
import LogoText from '../../assets/logo-text.svg';
import useQuery from '../../utilities/use-query';
import {useOperatorState} from './operator-interface';

const serviceIdEx = process.env.RAZZLE_APP_SERVICE_ID;

const Complete = () => {
    const location = useLocation();
    const {serviceId = serviceIdEx} = useQuery();
    const state = useOperatorState().completedReports[serviceId][0];
    const exportReport = () => {

    };
    return (
        <div className='padding-top-4rem padding-left-1dot5rem padding-right-1dot5rem display-flex flex-direction-column position-absolute left-0 right-0 top-0 bottom-0'>
            <div className='flex-1 display-flex flex-direction-column align-items-center'>
                <IconOperatorInterfaceSuccess className='height-12dot5rem'/>
                <h2 className='display-xs medium color-gray-900'>
                    Звіт надіслано
                </h2>
                <p className='padding-top-0dot5rem text-md color-gray-500'>Ми надіслали звіт до вашої бухгалтерії</p>
                {/* <div className='padding-top-2rem width-100percent'>
                    <Button {...{size: 'lg', color: 'primary-secondary', clickHandler: exportReport, fluid: 'always'}}>
                        <IconArrowBlockDown className='display-block'/>
                        <span className='padding-left-0dot5rem'>Завантажити звіт</span>
                    </Button>
                </div> */}
                <div className='padding-top-2rem width-100percent'>
                    <RouterLinkButton {...{size: 'lg', color: 'primary-secondary', to: `/${searchFilter(location.search)}`, fluid: 'always'}}>
                        Повернутись до холодильнику
                    </RouterLinkButton>
                </div>
            </div>
            <div className='padding-top-2rem padding-bottom-1dot5rem display-flex justify-content-center align-items-center'>
                <span className='text-sm medium color-gray-500'>Powered by</span>
                <LogoText className='display-block height-0dot875rem margin-left-0dot5rem margin-top-0dot125rem'/>
            </div>
        </div>
    );
}

export default React.memo(Complete);